// in src/Dashboard.js
import * as React from "react";
import Card from "@mui/material/Card";
import { Grid, Typography } from "@mui/material";
import { isToday } from "date-fns";
import {
    Link,
    useCreateController,
    useGetList,
    useNotify,
    useTheme,
} from "react-admin";
import { getTokenData } from "../utils/token-data";
import { onMutateError } from "../utils/on-error";
import { useTranslate } from "react-admin";
import EngineeringIcon from "@mui/icons-material/Engineering";
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import ParkIcon from '@mui/icons-material/Park';

const Dashboard = () => {
    const translate = useTranslate();
    const notify = useNotify();
    const { save: saveWorkday } = useCreateController({
        resource: "workdays",
        redirect: false,
        mutationOptions: {
            onError: (error) => onMutateError(error, notify),
        },
    });
    const { data, isFetched } = useGetList("workdays", {
        filter: {
            "user.id": getTokenData().id,
        },
    });

    const isWorkdayCreated = () => {
        return isFetched && data && data.some((day) => isToday(new Date(day.date)));
    };

    return (
        <Card style={{ marginTop: '20px' }}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Card
                        onClick={() => {
                            !isWorkdayCreated() &&
                                saveWorkday(
                                    {
                                        user: getTokenData().id,
                                        date: new Date(
                                            new Date().getTime() -
                                            new Date().getTimezoneOffset() * 60000
                                        ),
                                    },
                                    {
                                        onSuccess: () => {
                                            window.location.reload(false);
                                        },
                                    }
                                );
                        }}
                        style={{
                            height: '200px',
                            marginLeft: '10px',
                            marginTop: '10px',
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            ...(isWorkdayCreated()
                                ? { color: "gray" }
                                : { cursor: "pointer" }),
                        }}
                    >
                        <Grid container>
                            <Grid item xs={12} style={{
                                justifyContent: "center",
                                display: "flex",
                            }}>
                                <EngineeringIcon
                                    fontSize="large"
                                    style={{ fontSize: "400%" }}
                                />
                            </Grid>
                            <Grid item xs={12} style={{
                                justifyContent: "center",
                                display: "flex",
                            }}>
                                <Typography variant="h7" gutterBottom paragraph={true}>
                                    {translate("workdays.addWorkday")}
                                </Typography>{" "}
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Link to="/holidays/create">
                        <Card
                            style={{
                                height: '200px',
                                marginRight: '10px',
                                marginTop: '10px',
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                            }}
                        >
                            <Grid container>
                                <Grid item xs={12} style={{
                                    justifyContent: "center",
                                    display: "flex",
                                }}>
                                    <ParkIcon
                                        fontSize="large"
                                        style={{ fontSize: "400%" }}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{
                                    justifyContent: "center",
                                    display: "flex",
                                }}>
                                    <Typography variant="h7" gutterBottom paragraph={true}>
                                        {translate("holidays.addHolidays")}
                                    </Typography>{" "}
                                </Grid>
                            </Grid>
                        </Card>
                    </Link>
                </Grid>
                {/* <Grid item xs={6}>
                    <Card
                        style={{
                            height: '20 px',
                            marginLeft: '2 px',
                            marginBottom: '2 px',
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                        }}
                    >
                        <Typography variant="h5" gutterBottom>
                            {translate("travel.title")}
                        </Typography>
                    </Card>
                </Grid> */}
                <Grid item xs={6}>
                    <Link to="/overtime/create">
                        <Card
                            style={{
                                height: '200px',
                                marginLeft: '10px',
                                marginBottom: '10px',
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                            }}
                        >
                            <Typography variant="h5" gutterBottom>
                                <Grid container>
                                    <Grid item xs={12} style={{
                                        justifyContent: "center",
                                        display: "flex",
                                    }}>
                                        <MoreTimeIcon
                                            fontSize="large"
                                            style={{ fontSize: "300%" }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{
                                        justifyContent: "center",
                                        display: "flex",
                                    }}>
                                        <Typography variant="h7" gutterBottom paragraph={true}>
                                            {translate("overtime.addOvertime")}
                                        </Typography>{" "}
                                    </Grid>
                                </Grid>                            </Typography>
                        </Card>
                    </Link>
                </Grid>
            </Grid>
        </Card>
    );
};

export default Dashboard;
