import * as React from "react";
import {
  AutocompleteInput,
  Create,
  DateInput,
  NumberInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
  required,
  useNotify,
  useTranslate,
} from "react-admin";
import { format } from "date-fns";
import { validateDate } from "../utils/date/validate-date";
import { onMutateError } from "../utils/on-error";
import { getTokenData, isUser } from "../utils/token-data";

const validateForm = (values) => {
  const errors = {};

  if (!values.user) {
    errors.user = 'User must be defined';
  }

  if (new Date(values.dateFrom) > new Date(values.dateTo)) {
    errors.date = 'Błędne daty';
  }

  const dateFromValidationResult = validateDate(new Date(values.dateFrom), { maxDaysAgo: 5, maxFutureDays: 90 });
  if (isUser() && (dateFromValidationResult)) {
    errors.dateFrom = dateFromValidationResult;
  }

  return errors
};

const HolidayCreate = (props) => {
  const notify = useNotify();
  const translate = useTranslate();

  return (
    <Create
      {...props}
      mutationOptions={{ onError: (error) => onMutateError(error, notify) }}
    >
      <SimpleForm validate={validateForm}>
        <DateInput
          source="dateFrom"
          defaultValue={
            format(new Date(
              new Date().getTime() - new Date().getTimezoneOffset() * 60000
            ), 'yyyy-MM-dd')
          }
          required
          label={translate("dateFrom")}
        />
        <DateInput source="dateTo" required label={translate("dateTo")} />
        <TextInput
          source="notes"
          multiline
          fullWidth
          label={translate("holidays.notes")}
        />
        {
          isUser() ? (<NumberInput
            source="user"
            hidden={true}
            disabled
            defaultValue={getTokenData().id}
            style={{ display: "none" }}
          />) : <ReferenceInput source="user" reference="users" validate={required()} filter={{ role: 'user' }} isRequired >
            <AutocompleteInput label="User" optionText={value => `${value.firstName} ${value.lastName}`} />
          </ReferenceInput>
        }
      </SimpleForm>
    </Create>
  );
};

export default HolidayCreate;
