import HolidayCreate from "./HolidayCreate";
import HolidayEdit from "./HolidayEdit";
import HolidayList from "./HolidayList";

const Holidays = {
  list: HolidayList,
  create: HolidayCreate,
  edit: HolidayEdit,
};

export default Holidays;
