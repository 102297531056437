import * as React from "react";
import crudProvider from "ra-data-nestjsx-crud";
import { Admin, CustomRoutes, fetchUtils, radiantLightTheme, Resource } from "react-admin";
import { Route } from "react-router-dom";
import authProvider from "./authProviderV2";
import Users from "./users";
import Overtime from "./overtime";
import Holidays from "./holidays";
import { isAdmin, isUser } from "./utils/token-data";
import Dashboard from "./dashboard/dashboard";
import SickLeave from "./sick-leave";
import Workdays from "./workdays";
import Summary from "./summary/summary";
import { MyMenu } from "./Layout";
import { i18nProvider } from "./i18nProvider";
import Trip from "./trip";

const httpClient = async (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("token");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = crudProvider(
  process.env.REACT_APP_API_URL,
  (url, options) => httpClient(url, options)
);

const App = () => {
  return (
    <Admin
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      dashboard={isUser() ? Dashboard : undefined}
      disableTelemetry
      requireAuth
      menu={MyMenu}
      theme={radiantLightTheme}
    >
      {isAdmin() && <Resource name="users" {...Users} />}
      <Resource name="workdays" {...Workdays} />
      <Resource name="overtime" {...Overtime} />
      <Resource name="holidays" {...Holidays} />
      <Resource name="sick-leave" {...SickLeave} />
      <Resource name="trips" {...Trip} />
      <CustomRoutes>
        <Route path="/summary" element={<Summary />} />
      </CustomRoutes>
    </Admin>
  )
};

export default App;
