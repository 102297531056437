import { jwtDecode } from "./jwt";

export function getTokenData() {
    const token = localStorage.getItem("token");
    return token && jwtDecode(token);
}

export function isAdmin() {
    const token = localStorage.getItem("token");
    return token && jwtDecode(token).role === 'admin';
}

export function isUser() {
    const token = localStorage.getItem("token");
    return token && jwtDecode(token).role === 'user';
}