import { AutocompleteInput, ReferenceInput, useTranslate } from "react-admin";
import {
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  List,
  useRecordContext,
} from "react-admin";
import { getTokenData, isAdmin } from "../utils/token-data";

const SickLeaveEditButton = () => {
  const record = useRecordContext();
  if (!isAdmin() && record.approvalStatus !== "pending") return null;
  return <EditButton />;
};


const sickLeaveFilters = [
  <ReferenceInput label="User" source="user.id" reference="users" filter={{ role: 'user' }} >
    <AutocompleteInput label="User" optionText={value => `${value.firstName} ${value.lastName}`} />
  </ReferenceInput>
];

const SickLeaveList = () => {
  const translate = useTranslate();

  return (
    <List
      filterDefaultValues={
        isAdmin()
          ? {}
          : {
            "user.id": getTokenData().id,
          }
      }
      sort={{ field: 'dateFrom', order: 'DESC' }}
      filters={isAdmin() ? sickLeaveFilters : []}
    >
      <Datagrid>
        {isAdmin() && (
          <FunctionField
            label={translate("sickLeave.name")}
            render={(record) =>
              `${record.user.firstName} ${record.user.lastName}`
            }
          />
        )}
        <DateField source="dateFrom" label={translate("dateFrom")} />
        <DateField source="dateTo" label={translate("dateTo")} />
        <FunctionField
          label={translate("sickLeave.approvalStatus")}
          render={(record) =>
            translate(`approvalStatus.${record.approvalStatus}`)
          }
        />
        <SickLeaveEditButton />
      </Datagrid>
    </List>
  );
};

export default SickLeaveList;
