import en from "ra-language-english";
import pl from "ra-language-polish";
import polyglotI18nProvider from "ra-i18n-polyglot";

const polishMessages = {
    monthlySummary: {
        title: "Podsumowanie",
        table: {
            name: "Osoba",
            summary: "Podsumowanie",
            month: "Miesiąc",
            holidays: "Urlop",
            sickDays: "L4",
            overtime: "Nadgodziny",
            workdays: "Dni robocze",
        },
    },
    users: {
        title: "Użytkownicy",
        firstName: "Imię",
        lastName: "Nazwisko",
    },
    workdays: {
        title: "Dni pracy",
        createTitle: "dzień pracy",
        addWorkday: "Dzień pracy",
    },
    overtime: {
        title: "Nadgodziny",
        amount: "Ilość godzin",
        approvalStatus: "Status",
        name: "Osoba",
        addOvertime: "Nadgodziny",
        notes: "Notatki",
    },
    trip: {
        title: "Przejazdy",
        amount: "Dystans (km)",
        approvalStatus: "Status",
        name: "Osoba",
        addTrip: "Trip",
        notes: "Notatki",
        distanceKm: "Dystans (km)",
        asDriver: "Jako kierowca?"
    },
    holidays: {
        title: "Urlop",
        approvalStatus: "Status",
        notes: "Notatki",
        name: "Osoba",
        addHolidays: "Urlop",
    },
    sickLeave: {
        title: "L4",
        approvalStatus: "Status",
        notes: "Notatki",
        name: "Osoba",
    },
    units: {
        days: "dni",
        hours: "godziny",
    },
    travel: {
        title: "Wyjazd",
    },
    dashboard: {
        workday: "Dzień roboczy",
    },
    date: "Data",
    dateFrom: "Od",
    dateTo: "Do",
    approvalStatus: {
        approved: "Zatwierdzony",
        rejected: "Odrzucony",
        pending: "Oczekujący",
    },
};

const englishMessages = {
    monthlySummary: {
        title: "Monthly Summary",
        table: {
            name: "Name",
            summary: "Summary",
            month: "Month",
            holidays: "Holidays",
            sickDays: "Sick days",
            overtime: "Overtime",
            workdays: "Workdays",
            trip: "Trips",
        },
    },
    users: {
        title: "Users",
    },
    workdays: {
        title: "Workdays",
        createTitle: "workday",
    },
    overtime: {
        title: "Overtime",
        amount: "Amount of hours",
        approvalStatus: "Approval status",
        notes: "Notes",
        name: "Name",
    },
    trip: {
        title: "Trip",
        distanceKm: "Distance (km)",
        approvalStatus: "Approval status",
        notes: "Notes",
        name: "Name",
        asDriver: "As driver?"
    },
    holidays: {
        title: "Holidays",
        approvalStatus: "Approval status",
        notes: "Notes",
        name: "Name",
    },
    sickLeave: {
        title: "Sick leave",
        approvalStatus: "Approval status",
        notes: "Notes",
        name: "Name",
    },
    units: {
        days: "days",
        hours: "hours",
    },
    travel: {
        title: "Travel",
    },
    dashboard: {
        workday: "Workday",
    },
    date: "Date",
    dateFrom: "From",
    dateTo: "Until",
    approvalStatus: {
        approved: "Approved",
        rejected: "Rejected",
        pending: "Pending",
    },
};

const translations = {
    en: {
        ...en,
        ...englishMessages,
    },
    pl: {
        ...pl,
        ...polishMessages,
    },
};

export const i18nProvider = polyglotI18nProvider(
    (locale) => translations[locale],
    "pl",
    [
        { locale: "pl", name: "Polski" },
        { locale: "en", name: "English" },
    ]
);
