import * as React from "react";
import { useTranslate } from "react-admin";
import { DateInput, DeleteButton, Edit, SaveButton, SimpleForm, Toolbar, useRecordContext } from "react-admin";
import { isAdmin } from "../utils/token-data";

const WorkdayTitle = ({ record }) => {
  const translate = useTranslate();

  return (
    <span>
      {translate('workdays.createTitle')} {record ? `"${record.dateFrom} - ${record.dateTo}"` : ""}
    </span>
  );
};

const WorkdayEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
    <WorkdayDeleteButton />
  </Toolbar>
);

const WorkdayDeleteButton = () => {
  const record = useRecordContext();
  if (!isAdmin() && record.approvalStatus !== "pending") return null;
  return (
    <DeleteButton
      style={{
        marginLeft: "auto",
        marginRight: 0,
      }}
    />
  );
};

const WorkdayEdit = (props) => {
  const translate = useTranslate()
  return (
    <Edit title={<WorkdayTitle />} {...props}>
      <SimpleForm toolbar={<WorkdayEditToolbar />}>
        <DateInput source="date" label={translate('date')} />
      </SimpleForm>
    </Edit>
  );
};

export default WorkdayEdit;
