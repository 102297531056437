import * as React from "react";
import { BooleanInput, TextInput, useTranslate } from "react-admin";
import {
  DateInput,
  DeleteButton,
  Edit,
  NumberInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  Toolbar,
  useNotify,
  useRecordContext,
} from "react-admin";
import { onMutateError } from "../utils/on-error";
import { isAdmin, isUser } from "../utils/token-data";

const TripTitle = ({ record }) => {
  return <span>Trip {record ? `"${record.date}"` : ""}</span>;
};

const TripEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
    <TripDeleteButton />
  </Toolbar>
);

const TripDeleteButton = () => {
  const record = useRecordContext();
  if (!isAdmin() && record.approvalStatus !== "pending") return null;
  return (
    <DeleteButton
      style={{
        marginLeft: "auto",
        marginRight: 0,
      }}
    />
  );
};

const TripEdit = (props) => {
  const notify = useNotify();
  const translate = useTranslate();

  return (
    <Edit
      title={<TripTitle />}
      mutationMode="pessimistic"
      mutationOptions={{ onError: (error) => onMutateError(error, notify) }}
      {...props}
    >
      <SimpleForm toolbar={<TripEditToolbar />}>
        <DateInput
          source="date"
          required
          disabled
          label={translate("date")}
        />
        <NumberInput
          source="distanceKm"
          disabled
          required
          label={translate("trip.distanceKm")}
        />
        <BooleanInput
          source="asDriver"
          disabled
          required
          label={translate("trip.asDriver")}
        />
        <SelectInput
          source="approvalStatus"
          choices={[
            { id: "approved", name: translate('approvalStatus.approved') },
            { id: "pending", name: translate('approvalStatus.pending') },
            { id: "rejected", name: translate('approvalStatus.rejected') },
          ]}
          disabled={isUser()}
          validate={required()}
          label={translate("trip.approvalStatus")}
        />
        <TextInput
          source="notes"
          multiline
          fullWidth
          label={translate("trip.notes")}
        />
        <NumberInput
          source="user"
          hidden={true}
          disabled
          style={{ display: "none" }}
        />
      </SimpleForm>
    </Edit>
  );
};

export default TripEdit;
