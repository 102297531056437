import SickLeaveCreate from "./SickLeaveCreate";
import SickLeaveEdit from "./SickLeaveEdit";
import SickLeaveList from "./SickLeaveList";

const SickLeave = {
  list: SickLeaveList,
  create: SickLeaveCreate,
  edit: SickLeaveEdit,
};

export default SickLeave;
