import UserCreate from "./UserCreate";
import UserEdit from "./UserEdit";
import UsersList from "./UsersList";

const Users = {
  list: UsersList,
  create: UserCreate,
  edit: UserEdit,
};

export default Users;
