import { Card, CardContent } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { Title, useTranslate } from "react-admin";
import { SummaryUserRow } from "./SummaryRow";

const Summary = () => {
    const [summary, setSummary] = React.useState([]);
    const translate = useTranslate();

    React.useEffect(() => {
        const getSummary = async () => {
            const data = await fetch(
                process.env.REACT_APP_API_URL + "/schedule/summary",
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );
            const json = await data.json();
            const usersDict = json.reduce((acc, row) => {
                if (!acc[row.userId]) {
                    acc[row.userId] = {
                        userId: row.userId,
                        firstName: row.firstName,
                        lastName: row.lastName,
                        summaries: [],
                    };
                }

                acc[row.userId].summaries.push(row);
                return acc;
            }, {});
            setSummary(Object.values(usersDict));
        };
        getSummary();
    }, []);

    return (
        <Card>
            <Title title={translate("monthlySummary.title")} />
            <CardContent style={{ paddingTop: "5px" }}>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow key="summary_table_header">
                                <TableCell style={{ width: 50 }} />
                                <TableCell>{translate("monthlySummary.table.name")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(summary || []).map((row) => (
                                <SummaryUserRow key={row.name} row={row} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    );
};

export default Summary;
