import * as React from "react";
import { required } from "react-admin";
import { useTranslate } from "react-admin";
import { Edit, NumberInput, SelectInput, SimpleForm, TextInput } from "react-admin";

const UserTitle = ({ record }) => {
  return <span>User {record ? `"${record.firstName}"` : ""}</span>;
};

const UserEdit = (props) => {
  const translate = useTranslate();

  return (
    <Edit title={<UserTitle />} {...props}>
      <SimpleForm>
        <TextInput source="firstName" required label={translate('users.firstName')} />
        <TextInput source="lastName" required label={translate('users.lastName')} />
        <TextInput source="username" required />
        <NumberInput
          source="password"
          min={1000}
          parse={(value) => value?.toString()}
        />
        <SelectInput
          source="role"
          choices={[{ id: 'user', name: 'user' }, { id: 'admin', name: 'admin' }]}
          validate={required()}
          required
        />
      </SimpleForm>
    </Edit>
  );
};

export default UserEdit;
