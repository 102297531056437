import * as React from "react";
import {
  AutocompleteInput,
  Create,
  DateInput,
  NumberInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
  required,
  useNotify,
} from "react-admin";
import { getTokenData, isUser } from "../utils/token-data";
import { validateDate } from "../utils/date/validate-date";
import { onMutateError } from "../utils/on-error";
import { useTranslate } from "react-admin";

const validateForm = (values) => {
  const errors = {};

  if (!values.user) {
    errors.user = 'User must be defined';
  }

  const dateResult = validateDate(new Date(values.date), { maxDaysAgo: 6, maxFutureDays: 0 })
  if (isUser() && dateResult) {
    errors.date = dateResult;
  }

  return errors
};


const OvertimeCreate = (props) => {
  const notify = useNotify();
  const translate = useTranslate();

  return (
    <Create
      {...props}
      mutationOptions={{ onError: (error) => onMutateError(error, notify) }}
    >
      <SimpleForm
        validate={validateForm}
      >
        <DateInput
          source="date"
          required
          defaultValue={new Date()}
          label={translate("date")}
        />
        <NumberInput
          source="hoursAmount"
          required
          label={translate("overtime.amount")}
        />
        <TextInput
          source="notes"
          multiline
          fullWidth
          label={translate("overtime.notes")}
        />
        {
          isUser() ? (<NumberInput
            source="user"
            hidden={true}
            disabled
            defaultValue={getTokenData().id}
            style={{ display: "none" }}
          />) : <ReferenceInput source="user" reference="users" validate={required()} filter={{ role: 'user' }} isRequired >
            <AutocompleteInput label="User" optionText={value => `${value.firstName} ${value.lastName}`} />
          </ReferenceInput>
        }
      </SimpleForm>
    </Create>
  );
};

export default OvertimeCreate;
