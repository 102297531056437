import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import FileSaver from 'file-saver';
import * as React from "react";
import { Button, useTranslate } from "react-admin";
import { Link } from "react-router-dom";
import { isAdmin } from "../utils/token-data";
import { getBusinessDaysCount } from "./business-days";
import { getDaysInMonth, isSameDay } from 'date-fns'
import Holidays from "date-holidays";

function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

async function downloadMonthlyReport(userId, month) {
    month.setDate(15)
    const res = await fetch(
        process.env.REACT_APP_API_URL + "/schedule/report/csv",
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                userId,
                date: month.toISOString()
            })
        }
    );
    const data = await res.text()
    const blob = new Blob([data], { type: "text/csv;charset=utf-8" });
    FileSaver.saveAs(blob, "report.csv");
}

export function SummaryUserRow(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(!isAdmin());

    return (
        <React.Fragment>
            <TableRow
                sx={{ "& > *": { borderBottom: "unset" } }}
                style={{ marginBottom: "100px" }}
                key="user_summary_expandable"
            >
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {`${row.firstName} ${row.lastName}`}
                </TableCell>
            </TableRow>
            <TableRow key="user_summary_details">
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <UserSummaryDetailsTable userRow={row} />
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const UserSummaryDetailsTable = ({ userRow: row }) => {
    const translate = useTranslate();
    const [open, setOpen] = React.useState(!isAdmin());
    const [summary, setSummary] = React.useState(null);

    return (
        <Box sx={{ margin: 1 }}>
            <Typography variant="h6" gutterBottom component="div">
                {translate("monthlySummary.table.summary")}
            </Typography>
            <Table size="small">
                <TableHead>
                    <TableRow key={row.userId.toString()}>
                        <TableCell>
                            {translate("monthlySummary.table.month")}
                        </TableCell>
                        <TableCell>
                            <Link
                                to={{
                                    pathname: "/workdays",
                                    search: `filter=${JSON.stringify({
                                        user: { id: row.userId },
                                    })}`,
                                }}
                            >
                                {translate("monthlySummary.table.workdays")}
                            </Link>
                        </TableCell>
                        <TableCell>
                            <Link
                                to={{
                                    pathname: "/overtime",
                                    search: `filter=${JSON.stringify({
                                        user: { id: row.userId },
                                    })}`,
                                }}
                            >
                                {translate("monthlySummary.table.overtime")}
                            </Link>
                        </TableCell>
                        <TableCell align="right">
                            <Link
                                to={{
                                    pathname: "/holidays",
                                    search: `filter=${JSON.stringify({
                                        user: { id: row.userId },
                                    })}`,
                                }}
                            >
                                {translate("monthlySummary.table.holidays")}
                            </Link>
                        </TableCell>
                        <TableCell align="right">
                            <Link
                                to={{
                                    pathname: "/sick-leave",
                                    search: `filter=${JSON.stringify({
                                        user: { id: row.userId },
                                    })}`,
                                }}
                            >
                                {translate("monthlySummary.table.sickDays")}
                            </Link>
                        </TableCell>
                        <TableCell align="right">
                        </TableCell>
                        <TableCell align="right">
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {row.summaries.map((monthlySummary) => (
                        <>
                            <TableRow key={monthlySummary.month}>
                                <TableCell component="th" scope="row">
                                    {capitalize(new Date(monthlySummary.month).toLocaleDateString('pl-PL', { year: 'numeric', month: 'long' }))}
                                </TableCell>
                                <TableCell>
                                    {monthlySummary.workdays} / {getBusinessDaysCount(new Date(monthlySummary.month).getUTCFullYear(), new Date(monthlySummary.month).getUTCMonth() + 1)} {translate("units.days")}
                                </TableCell>
                                <TableCell>
                                    {monthlySummary.overtime} {translate("units.hours")}
                                </TableCell>
                                <TableCell align="right">
                                    {monthlySummary.holidays} {translate("units.days")}
                                </TableCell>
                                <TableCell align="right">
                                    {monthlySummary.sickDays} {translate("units.days")}
                                </TableCell>
                                {isAdmin() && <TableCell align="right">
                                    <Button label="Report" variant="primary" onClick={() => downloadMonthlyReport(monthlySummary.userId, new Date(monthlySummary.month))} />
                                </TableCell>
                                }
                                <TableCell align="right">
                                    <Button variant="primary" onClick={() => {
                                        setSummary(monthlySummary);
                                        setOpen(true);
                                    }}>
                                        Details
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </>
                    ))}
                </TableBody>
            </Table>
            <MonthViewDialog
                open={open}
                onClose={() => setOpen(false)}
                monthlySummary={summary}
            />
        </Box>)
}


function MonthViewDialog(props) {
    const { monthlySummary } = props;

    if (!monthlySummary) {
        return <></>
    }

    return (
        <Dialog onClose={props.onClose} open={props.open} maxWidth="xl" fullWidth={true}>
            <DialogTitle>{capitalize(new Date(monthlySummary.month).toLocaleDateString('pl-PL', { year: 'numeric', month: 'long' }))}</DialogTitle>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Day
                        </TableCell>
                        {Array.from({ length: getDaysInMonth(new Date(monthlySummary.month)) }).map((_, i) => {
                            return (
                                getHeaderDaySymbol(monthlySummary, i + 1)
                            )
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow key={monthlySummary.month}>
                        <TableCell component="th" scope="row">
                            Hours worked
                        </TableCell>
                        {Array.from({ length: getDaysInMonth(new Date(monthlySummary.month)) }).map((_, i) => {
                            return getDaySymbol(monthlySummary, i + 1)
                        })}
                    </TableRow>
                </TableBody>
            </Table>
            <Table size="small" style={{ marginTop: '30px', width: '150px' }}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow key='workday'>
                        <TableCell component="th" scope="row">
                            Workday
                        </TableCell>
                        <TableCell component="th" scope="row" style={{ backgroundColor: 'white' }}>
                        </TableCell>
                    </TableRow>
                    <TableRow key='bank_holiday'>
                        <TableCell scope="row">
                            Weekend or Bank Holiday
                        </TableCell>
                        <TableCell scope="row" style={{ backgroundColor: '#ff9999' }}>
                        </TableCell>
                    </TableRow>
                    <TableRow key='holiday'>
                        <TableCell component="th" scope="row">
                            Holiday
                        </TableCell>
                        <TableCell component="th" scope="row" style={{ backgroundColor: '#99ffbb' }}>
                        </TableCell>
                    </TableRow>
                    <TableRow key='sick_leave'>
                        <TableCell component="th" scope="row">
                            Sick Leave
                        </TableCell>
                        <TableCell component="th" scope="row" style={{ backgroundColor: '#ffffb3' }}>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Dialog>
    )
}

function getDaySymbol(monthlySummary, day) {
    const date = new Date(monthlySummary.month);
    date.setDate(day);
    const holidays = new Holidays('PL');
    const dayOfWeek = date.getDay();
    const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;

    let color = (isWeekend || holidays.isHoliday(date)) ? '#ff9999' : 'white';
    const isWorkday = (monthlySummary.work_days || []).find(d => new Date(d).getDate() === day);
    const isHoliday = (monthlySummary.holiday_days || []).find(d => new Date(d).getDate() === day);
    const isSickLeaveDay = (monthlySummary.sick_leave_days || []).find(d => new Date(d).getDate() === day);
    const isWorkingDay = isWorkday || isHoliday || isSickLeaveDay
    const overtimeHours = (monthlySummary.overtime_days || []).find(d => {
        return isSameDay(new Date(d.date), date)
    })?.hours || 0;
    if (isSickLeaveDay) {
        color = "#ffffb3"
    }

    if (isHoliday) {
        color = "#99ffbb"
    }

    // if (overtimeHours > 0) {
    //     color = "#B9EBDB"
    // }

    if (isWorkingDay) {
        return (<TableCell key={day} style={{ backgroundColor: color }} >
            {8 + overtimeHours}{overtimeHours > 0 ? '*' : ''}
        </ TableCell>)
    }

    return (<TableCell key={day} style={{ backgroundColor: color }} >
    </TableCell>)
}

function getHeaderDaySymbol(monthlySummary, day) {
    const date = new Date(monthlySummary.month);
    date.setDate(day);
    const holidays = new Holidays('PL');
    const dayOfWeek = date.getDay();
    const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;

    let color = (isWeekend || holidays.isHoliday(date)) ? ' #ff9999' : 'white';

    return (<TableCell key={day} style={{ backgroundColor: color }} >
        {day}
    </TableCell>)
}
