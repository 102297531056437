export const validateDate = (date, { maxDaysAgo, maxFutureDays }) => {
    const daysAgoDate = new Date()
    daysAgoDate.setDate(new Date().getDate() - maxDaysAgo)
    daysAgoDate.setHours(23)
    daysAgoDate.setMinutes(59)
    daysAgoDate.setSeconds(59)

    const maxFutureDate = new Date()
    maxFutureDate.setDate(new Date().getDate() + maxFutureDays + 1)
    maxFutureDate.setHours(0)
    maxFutureDate.setMinutes(0)
    maxFutureDate.setSeconds(0)

    if (date > maxFutureDate) {
        return "Date can't be from the future"
    }

    if (date < daysAgoDate) {
        return `Date can't be earlier than ${maxDaysAgo} days ago`
    }
}