import OvertimeCreate from "./OvertimeCreate";
import OvertimeEdit from "./OvertimeEdit";
import OvertimeList from "./OvertimeList";

const Overtime = {
  list: OvertimeList,
  create: OvertimeCreate,
  edit: OvertimeEdit,
};

export default Overtime;
