import * as React from "react";
import { Create, NumberInput, SimpleForm, TextInput } from "react-admin";

const UserCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="firstName" required />
        <TextInput source="lastName" required />
        <TextInput source="username" required />
        <NumberInput
          source="password"
          required
          min={1000}
          parse={(value) => value?.toString()}
        />
      </SimpleForm>
    </Create>
  );
};

export default UserCreate;
