import * as React from "react";
import {
  DateInput,
  DeleteButton,
  Edit,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useNotify,
  useRecordContext,
} from "react-admin";
import { isAdmin, isUser } from "../utils/token-data";
import { onMutateError } from "../utils/on-error";
import { useTranslate } from "react-admin";

const HolidayTitle = ({ record }) => {
  return (
    <span>
      Holiday {record ? `"${record.dateFrom} - ${record.dateTo}"` : ""}
    </span>
  );
};

const HolidayEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
    <HolidayDeleteButton />
  </Toolbar>
);

const HolidayDeleteButton = () => {
  const record = useRecordContext();
  if (!isAdmin() && record.approvalStatus !== "pending") return null;
  return (
    <DeleteButton
      style={{
        marginLeft: "auto",
        marginRight: 0,
      }}
    />
  );
};

const validateDates = (values) => {
  const errors = {};

  if (new Date(values.dateFrom) > new Date(values.dateTo)) {
    errors.firstName = 'Błędne daty';
  }

  return errors
};

const HolidayEdit = (props) => {
  const notify = useNotify();
  const translate = useTranslate();

  return (
    <Edit
      title={<HolidayTitle />}
      mutationOptions={{ onError: (error) => onMutateError(error, notify) }}
      mutationMode="pessimistic"
      {...props}
    >
      <SimpleForm validate={validateDates} toolbar={<HolidayEditToolbar />}>
        <DateInput source="dateFrom" disabled label={translate("dateFrom")} />
        <DateInput source="dateTo" disabled label={translate("dateTo")} />
        <SelectInput
          source="approvalStatus"
          choices={[
            { id: "approved", name: translate('approvalStatus.approved') },
            { id: "pending", name: translate('approvalStatus.pending') },
            { id: "rejected", name: translate('approvalStatus.rejected') },
          ]}
          disabled={isUser()}
          validate={required()}
          label={translate("holidays.approvalStatus")}
        />
        <TextInput
          source="notes"
          multiline
          fullWidth
          label={translate("holidays.notes")}
        />
      </SimpleForm>
    </Edit>
  );
};

export default HolidayEdit;
