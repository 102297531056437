import { AutocompleteInput, ReferenceInput, useTranslate } from "react-admin";
import {
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  List,
  TextField,
  useRecordContext,
} from "react-admin";
import { getTokenData, isAdmin } from "../utils/token-data";

const OvertimeEditButton = () => {
  const record = useRecordContext();
  if (!isAdmin() && record.approvalStatus !== "pending") return null;
  return <EditButton />;
};

const overtimeFilters = [
  <ReferenceInput label="User" source="user.id" reference="users" filter={{ role: 'user' }} >
    <AutocompleteInput label="User" optionText={value => `${value.firstName} ${value.lastName}`} />
  </ReferenceInput>
];

const OvertimeList = () => {
  const translate = useTranslate();

  return (
    <List
      filterDefaultValues={
        isAdmin()
          ? {}
          : {
            "user.id": getTokenData().id,
          }
      }
      sort={{ field: 'date', order: 'DESC' }}
      filters={isAdmin() ? overtimeFilters : []}
    >
      <Datagrid>
        {isAdmin() && (
          <FunctionField
            label={translate("overtime.name")}
            render={(record) =>
              `${record.user.firstName} ${record.user.lastName}`
            }
          />
        )}
        <DateField source="date" label={translate("date")} />
        <TextField source="hoursAmount" label={translate("overtime.amount")} />
        <FunctionField
          label={translate("overtime.approvalStatus")}
          render={record => translate(`approvalStatus.${record.approvalStatus}`)}
        />
        <OvertimeEditButton />
      </Datagrid>
    </List>
  );
};

export default OvertimeList;
