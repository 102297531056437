import TripCreate from "./TripCreate";
import TripEdit from "./TripEdit";
import TripList from "./TripList";

const Trip = {
  list: TripList,
  create: TripCreate,
  edit: TripEdit,
};

export default Trip;
