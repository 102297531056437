import { useTranslate } from "react-admin";
import { Datagrid, EditButton, List, TextField } from "react-admin";

const UsersList = () => {
  const translate = useTranslate();

  return (
    <List>
      <Datagrid>
        <TextField source="id" />
        <TextField source="firstName" label={translate('users.firstName')} />
        <TextField source="lastName" label={translate('users.lastName')} />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default UsersList;
