import Holidays from 'date-holidays';

export function getBusinessDaysCount(year, month) {
  const holidays = new Holidays('PL');
  const startDate = new Date(year, month - 1, 1);
  const endDate = new Date(year, month, 0);
  let count = 0;

  for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
    const dayOfWeek = date.getDay();
    const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;
    const isHoliday = holidays.isHoliday(date);

    if (!isWeekend && !isHoliday) {
      count++;
    }
  }

  return count;
}