import * as React from "react";
import { TextInput, useTranslate } from "react-admin";
import {
  DateInput,
  DeleteButton,
  Edit,
  NumberInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  Toolbar,
  useNotify,
  useRecordContext,
} from "react-admin";
import { validateDate } from "../utils/date/validate-date";
import { onMutateError } from "../utils/on-error";
import { isAdmin, isUser } from "../utils/token-data";

const OvertimeTitle = ({ record }) => {
  return <span>Overtime {record ? `"${record.date}"` : ""}</span>;
};

const OvertimeEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
    <OvertimeDeleteButton />
  </Toolbar>
);

const OvertimeDeleteButton = () => {
  const record = useRecordContext();
  if (!isAdmin() && record.approvalStatus !== "pending") return null;
  return (
    <DeleteButton
      style={{
        marginLeft: "auto",
        marginRight: 0,
      }}
    />
  );
};

const OvertimeEdit = (props) => {
  const notify = useNotify();
  const translate = useTranslate();

  return (
    <Edit
      title={<OvertimeTitle />}
      mutationMode="pessimistic"
      mutationOptions={{ onError: (error) => onMutateError(error, notify) }}
      {...props}
    >
      <SimpleForm toolbar={<OvertimeEditToolbar />}>
        <DateInput
          source="date"
          required
          disabled
          label={translate("date")}
        />
        <NumberInput
          source="hoursAmount"
          disabled
          required
          label={translate("overtime.amount")}
        />
        <SelectInput
          source="approvalStatus"
          choices={[
            { id: "approved", name: translate('approvalStatus.approved') },
            { id: "pending", name: translate('approvalStatus.pending') },
            { id: "rejected", name: translate('approvalStatus.rejected') },
          ]}
          disabled={isUser()}
          validate={required()}
          label={translate("overtime.approvalStatus")}
        />
        <TextInput
          source="notes"
          multiline
          fullWidth
          label={translate("overtime.notes")}
        />
        <NumberInput
          source="user"
          hidden={true}
          disabled
          style={{ display: "none" }}
        />
      </SimpleForm>
    </Edit>
  );
};

export default OvertimeEdit;
