import WorkdayCreate from "./WorkdayCreate";
import WorkdayEdit from "./WorkdayEdit";
import WorkdayList from "./WorkdayList";

const Workdays = {
  list: WorkdayList,
  edit: WorkdayEdit,
  create: WorkdayCreate,
};

export default Workdays;
