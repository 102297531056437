import {
  AutocompleteInput,
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  List,
  ReferenceInput,
  useRecordContext,
} from "react-admin";
import { getTokenData, isAdmin, isUser } from "../utils/token-data";
import { endOfToday } from "date-fns";
import { useTranslate } from "react-admin";

const WorkdayEditButton = () => {
  const record = useRecordContext();
  if (isUser() && new Date(record.date) < endOfToday()) return null;
  return <EditButton />;
};

const workdaysFilters = [
  <ReferenceInput
    label="User"
    source="user.id"
    reference="users"
    filter={{ role: "user" }}
  >
    <AutocompleteInput
      label="User"
      optionText={(value) => `${value.firstName} ${value.lastName}`}
    />
  </ReferenceInput>,
];

const WorkdayList = () => {
  const translate = useTranslate();
  const userIdFilter = isAdmin()
    ? {}
    : {
      "user.id": getTokenData().id,
    };

  return (
    <List
      filterDefaultValues={{ ...userIdFilter }}
      sort={{ field: "date", order: "DESC" }}
      filters={isAdmin() ? workdaysFilters : []}
    >
      <Datagrid>
        {isAdmin() && (
          <FunctionField
            label="Name"
            render={(record) =>
              `${record.user.firstName} ${record.user.lastName}`
            }
          />
        )}
        <DateField source="date" label={translate("date")} />
        <WorkdayEditButton />
      </Datagrid>
    </List>
  );
};

export default WorkdayList;
