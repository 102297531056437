import * as React from "react";
import { Menu, useTranslate } from "react-admin";
import { isAdmin } from "./utils/token-data";
import GroupIcon from '@mui/icons-material/Group';
import SummarizeIcon from '@mui/icons-material/Summarize';
import EngineeringIcon from '@mui/icons-material/Engineering';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import ParkIcon from '@mui/icons-material/Park';
import SickIcon from '@mui/icons-material/Sick';
import DriveEtaIcon from '@mui/icons-material/DriveEta';

export const MyMenu = (props) => {
    const translate = useTranslate()

    return (
        <Menu {...props}>
            <Menu.DashboardItem />
            {isAdmin() && <Menu.Item to="/users" primaryText={translate('users.title')} leftIcon={<GroupIcon />} />}
            <Menu.Item to="/summary" primaryText={translate('monthlySummary.title')} leftIcon={<SummarizeIcon />} />
            <Menu.Item to="/workdays" primaryText={translate('workdays.title')} leftIcon={<EngineeringIcon />} />
            <Menu.Item to="/overtime" primaryText={translate('overtime.title')} leftIcon={<MoreTimeIcon />} />
            <Menu.Item to="/holidays" primaryText={translate('holidays.title')} leftIcon={<ParkIcon />} />
            <Menu.Item to="/sick-leave" primaryText={translate('sickLeave.title')} leftIcon={<SickIcon />} />
            <Menu.Item to="/trips" primaryText={translate('trip.title')} leftIcon={<DriveEtaIcon />} />
        </Menu>
    )
};
